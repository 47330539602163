#range-slider-gradient {
  height: 5px;
  border-radius: 12px;
}

#range-slider-gradient .range-slider__thumb {
    border: 4px solid #0073e6;
    box-sizing: border-box;
  }
  
  #range-slider-gradient .range-slider__thumb[data-lower] {
    background: #fff;
  }

  #range-slider-gradient .range-slider__thumb[data-upper] {
    background: #fff;
  }

  #range-slider-gradient .range-slider__range {
    background: #0073e6;
  }